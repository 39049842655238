/**
 * Create an action for when the settings are updated.
 *
 * {
 *     type: SETTINGS_UPDATED,
 *     settings: {
 *         audioOutputDeviceId: string,
 *         avatarURL: string,
 *         cameraDeviceId: string,
 *         displayName: string,
 *         email: string,
 *         localFlipX: boolean,
 *         micDeviceId: string,
 *         serverURL: string,
 *         startAudioOnly: boolean,
 *         startWithAudioMuted: boolean,
 *         startWithVideoMuted: boolean,
 *         startWithReactionsMuted: boolean
 *     }
 * }
 */
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED';
// hansoo added
export const TOGGLE_ANNOTATION_DRAW_ERASE = 'TOGGLE_ANNOTATION_DRAW_ERASE'; // Added new action type
export const TRIGGER_ANNOTATION_CLEAR = 'TRIGGER_ANNOTATION_CLEAR'; // Added new action type
export const TRIGGER_IMAGE_SNAPSHOT = 'TRIGGER_IMAGE_SNAPSHOT'; // Added new action type
export const TOGGLE_VIDEO_RECORDING = 'TOGGLE_VIDEO_RECORDING'; // Added new action type
export const LAST_HOVERED_BUTTON_INDEX = 'LAST_HOVERED_BUTTON_INDEX'; // Added new action type
export const UPDATE_UI_BUTTON_POSITION = 'UPDATE_UI_BUTTON_POSITION'; // Added new action type
export const UPDATE_UI_HAND_MENU_POSITION = 'UPDATE_UI_HAND_MENU_POSITION'; // Added new action type
export const TRIGGER_FLIP_HAND_UI = 'TRIGGER_FLIP_HAND_UI'; // Added new action type
export const TRIGGER_FLIP_WINDOW_UI = 'TRIGGER_FLIP_WINDOW_UI';
export const TOGGLE_MOUSE_HAND_ANNOTATION = 'TOGGLE_MOUSE_HAND_ANNOTATION';
