
import { HandLandmarker, FilesetResolver } from "./mylibs/mediapipe/task-vision/";


import {updateUIButtonPosition, updateUIHandMenuPosition } from '../base/settings/actions';


class HandTrack {
    constructor(videoElement, dispatch) {
        this.videoElement = videoElement;
        this.dispatch = dispatch; // Store the dispatch function
        this.videoElement.autoplay = true; // Video element is expected to be handled externally
        this.HandLandmarker = undefined;
        this.lastVideoTime = -1;
        this.hand_activated = false;
        this.skip_count = 0;
        this.skip_limit = 30;
        //
        //const localFlipX = useSelector(state => state['features/base/settings'].localFlipX);
        this.skip_hand_follow_count = 0;
        this.skip_hand_follow_limit = 2;
// const localFlipX = useSelector(state => state['features/base/settings'].localFlipX);
        // Initialize GestureRecognizer asynchronously
        this.menu_follow = false;
        this.initializeGestureRecognizer();
        this.missing_hand = true;
    }

    /**
     * Asynchronously loads the @mediapipe/tasks-vision module and initializes the GestureRecognizer
     */
async initializeGestureRecognizer() {
    try {

        console.log("hansoo 1 Creating FilesetResolver...");
        //const vision = await FilesetResolver.forVisionTasks("./mylibs/mediapipe/task-vision/wasm");  // Use local path for WASM file
        const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");
        // const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");

        console.log("hansoo 2 Creating HandLandmarker...");
        this.HandLandmarker = await HandLandmarker.createFromOptions(vision, {
            baseOptions: {
                // modelAssetPath: "https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task",

                modelAssetPath: "https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task",

                //modelAssetPath: "./mylibs/mediapipe/task-vision/gesture_recognizer.task",  // Local model path
                delegate: "GPU"
            },
            runningMode: "VIDEO",  // Assuming it's a video stream
            numHands: 2
        });

        console.log("hansoo 3 HandLandmarker created successfully. Ready for predictions.");
        this.startPrediction();
    } catch (error) {
        console.error("hansoo 4 Error initializing HandLandmarker:", error);
    }
}

    /**
     * Start prediction on the video element
     */
    startPrediction() {
        if (!this.HandLandmarker) {
            alert("hansoo Gesture recognizer is not loaded yet");
            return;
        }

        this.predict(); // Begin gesture recognition on the provided video element
    }

    /**
     * Detect gestures and log index finger position if hand is activated
     */


    // check pinch gesture end
    async predict() {
        const startTimeMs = performance.now();

        // Only predict if the video time has advanced
        if (this.lastVideoTime !== this.videoElement.currentTime) {
            this.lastVideoTime = this.videoElement.currentTime;

            // Perform gesture recognition on the current video frame
            try {
                const results = await this.HandLandmarker.detectForVideo(this.videoElement, Date.now());

                if (results && results.landmarks && results.handednesses) {
                    let leftHandData = null; // To store left hand data
                    let rightHandData = null; // To store right hand data
                    // let flipforremote = false;
                    // // flip if sending to others and hand is being used // not working
                    // const state = this._store.getState();
                    // const pinnedParticipant = state['features/large-video']?.participantId;
                    // if (pinnedParticipant)
                    // {
                    //     this._state._receiver = pinnedParticipant;
                    //     if (this._state._sender !== this._state._receiver)
                    //     {
                    //         // this._state._mouseX = 1 - this._state._mouseX;
                    //         flipforremote = true;
                    //     }
                    // }
                    // flip end
                    // console.log('hand debug->', results.handednesses);

                    results.handednesses.forEach((handedness, index) => {
                        const handType = handedness[0].categoryName; // "Left" or "Right"

                        if (handType === 'Left') {
                            const pinchResult = checkPinchGesture(results,index);
                            // const indexFingerTip = results.landmarks[index]?.[8]; // Index finger tip landmark
                            // if (indexFingerTip) {
                            //     leftHandData = {
                            //         activate: pinchResult.pinchDetected,
                            //         x: indexFingerTip.x,
                            //         // x: flipforremote ? 1 - indexFingerTip.x: indexFingerTip.x,
                            //         y: indexFingerTip.y
                            //     };
                            // }
                            const middleFingerBase = results.landmarks[index]?.[9]; // Middle finger base landmark
                            if (middleFingerBase) {
                                leftHandData = {
                                    activate: pinchResult.pinchDetected,
                                    x: middleFingerBase.x,
                                    // x: flipforremote ? 1 - middleFingerBase.x: middleFingerBase.x,
                                    y: middleFingerBase.y
                                };
                            }
                        }

                        if (handType === 'Right') {
                                const middleFingerBase = results.landmarks[index]?.[9]; // Middle finger base landmark
                                if (middleFingerBase) {
                                    rightHandData = {
                                        visible: true,
                                        x: middleFingerBase.x,
                                        // x: flipforremote ? 1 - middleFingerBase.x: middleFingerBase.x,
                                        y: middleFingerBase.y
                                    };
                                } else {
                                    rightHandData = { visible: false, x: 0, y: 0 };
                                }
                        }
                    });
                    // Dispatch events after processing all hands
                    if (leftHandData) {
                        const event = new CustomEvent('indexFingerTipDetected', {
                            detail: leftHandData
                        });
                        window.dispatchEvent(event);
                        this.dispatch(updateUIButtonPosition(leftHandData.activate, 1 - leftHandData.x, leftHandData.y));
                        this.missing_hand  = false;
                    }
                    else
                    {
                        if( !this.missing_hand )
                        {
                            this.dispatch(updateUIButtonPosition(false, 0, 0));
                            this.missing_hand  = true;
                        }
                    }


                    if (rightHandData) {
                        // console.log("Right Hand data:", rightHandData);
                        this.dispatch(updateUIHandMenuPosition(rightHandData.visible, 1 - rightHandData.x, rightHandData.y));
                        this.menu_follow = true;
                    }
                    else
                    {
                        if(this.menu_follow) // prevent mul
                        {
                            this.menu_follow = false;
                            this.dispatch(updateUIHandMenuPosition(false, 0, 0));
                        }
                    }
                }
            } catch (error) {
                console.error("hansoo Error during gesture recognition:", error);
            }
        }

        // Continue predicting
        window.requestAnimationFrame(() => this.predict());
    }


    destroy() {
        this.gestureRecognizer = undefined;
    }
}

// helper functions
// check  helper function, called by checkPinchGesture
function calculateDistance(point1, point2)
{
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy); // Return the distance between the two points
}
// Function to get the bounding box of the hand (based on wrist, thumb, and index finger landmarks)
function getHandBoundingBox(landmarks)
{
    const wrist = landmarks[0];  // Landmark 0 is the wrist
    const indexFingerTip = landmarks[8];  // Landmark 8 is the index finger tip
    const thumbTip = landmarks[4];  // Landmark 4 is the thumb tip

    // Calculate the width of the hand (distance between wrist and index finger)
    const width = calculateDistance(wrist, indexFingerTip);

    // You could also calculate the height of the hand if needed (distance between wrist and thumb tip)
    const height = calculateDistance(wrist, thumbTip);

    // Return the hand bounding box as an object with width and height
    return {
        width: width,
        height: height
    };
}
// check pinch gesture start // helper function, called by predict
function checkPinchGesture(results, index)
{
    const thumbTip = results.landmarks[index]?.[4]; // Thumb tip landmark
    const indexFingerTip = results.landmarks[index]?.[8]; // Index finger tip landmark

    // Ensure both landmarks are detected
    if (thumbTip && indexFingerTip) {
        // Calculate the distance between the thumb tip and index finger tip
        const pinchDistance = calculateDistance(thumbTip, indexFingerTip);

        // Get the hand bounding box (from wrist to index finger and thumb landmarks)
        const handBoundingBox = getHandBoundingBox(results.landmarks[index]);

        if (handBoundingBox) {
            // Normalize pinch distance by the hand's width (horizontal distance between wrist and index finger)
            const normalizedPinchDistance = pinchDistance / handBoundingBox.width;

            // Define a normalized pinch threshold
            // const pinchThreshold = 0.35; //0.25; //0.05; // Adjust this value based on your needs
            const pinchThreshold = 0.35; //0.25; //0.05; // Adjust this value based on your needs

            // If the normalized pinch distance is smaller than the threshold, it's considered a pinch
            if (normalizedPinchDistance < pinchThreshold) {
                //console.log("Pinch detected (normalized)!");
                // Return the pinch status along with the positions and normalized distance
                return {
                    pinchDetected: true,
                    thumbPosition: thumbTip,
                    indexFingerPosition: indexFingerTip,
                    normalizedPinchDistance: normalizedPinchDistance
                };
            }
        }
    }

    // If no pinch detected, return false
    return {
        pinchDetected: false
    };
}


export { HandTrack };

//let GestureRecognizer, FilesetResolver;
// import { GestureRecognizer, FilesetResolver } from "./mylibs/mediapipe/task-vision/";

// import {
//   HandLandmarker,
//   FilesetResolver
// } from "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0";

// to update ui button via redux
// import { useDispatch, useSelector } from 'react-redux';
// import {/* toggleVideoRecording, triggerImageSnapshot, toggleAnnotationDrawErase, triggerAnnotationClear, */updateUIButtonPosition, updateUIHandMenuPosition } from '../base/settings/actions';
// import { useDispatch, useSelector } from 'react-redux';
// const localFlipX = useSelector((state: IReduxState) => state['features/base/settings'].localFlipX);
