// @ts-expect-error
import { jitsiLocalStorage } from '@jitsi/js-utils';
import { escape } from 'lodash-es';
import { APP_WILL_MOUNT } from '../app/actionTypes';
import PersistenceRegistry from '../redux/PersistenceRegistry';
import ReducerRegistry from '../redux/ReducerRegistry';
import { assignIfDefined } from '../util/helpers';
import { SETTINGS_UPDATED } from './actionTypes';
// hansoo added
import { TOGGLE_VIDEO_RECORDING, TRIGGER_IMAGE_SNAPSHOT, TOGGLE_ANNOTATION_DRAW_ERASE, TRIGGER_ANNOTATION_CLEAR, UPDATE_UI_BUTTON_POSITION, LAST_HOVERED_BUTTON_INDEX, UPDATE_UI_HAND_MENU_POSITION, TRIGGER_FLIP_WINDOW_UI, TOGGLE_MOUSE_HAND_ANNOTATION } from './actionTypes';
/**
 * The default/initial redux state of the feature {@code base/settings}.
 *
 * @type Object
 */
const DEFAULT_STATE = {
    audioOutputDeviceId: undefined,
    avatarURL: undefined,
    cameraDeviceId: undefined,
    disableCallIntegration: undefined,
    disableCrashReporting: undefined,
    disableP2P: undefined,
    disableSelfView: false,
    displayName: undefined,
    email: undefined,
    localFlipX: true,
    maxStageParticipants: 1,
    micDeviceId: undefined,
    serverURL: undefined,
    hideShareAudioHelper: false,
    soundsIncomingMessage: true,
    soundsParticipantJoined: true,
    soundsParticipantKnocking: true,
    soundsParticipantLeft: true,
    soundsTalkWhileMuted: true,
    soundsReactions: true,
    startAudioOnly: false,
    startCarMode: false,
    startWithAudioMuted: false,
    startWithVideoMuted: false,
    userSelectedAudioOutputDeviceId: undefined,
    userSelectedCameraDeviceId: undefined,
    userSelectedMicDeviceId: undefined,
    userSelectedAudioOutputDeviceLabel: undefined,
    userSelectedCameraDeviceLabel: undefined,
    userSelectedNotifications: {
        'notify.chatMessages': true
    },
    userSelectedMicDeviceLabel: undefined,
    userSelectedSkipPrejoin: undefined,
    // hansoo Added toggle video recording state
    isRecording: false,
    isAnnotationDrawErase: true,
    uiButtonPosition: { visible: false, x: 0.5, y: 0.5 },
    // uiHandMenuPosition: {visible: false, x: '50%', y: 0 }, // Initial position of the button
    uiHandMenuPosition: { visible: false, x: 0, y: 0 },
    lastHoveredButtonIndex: 0,
    // isflipHandUI : false,
    uiMouseOrHand: true,
    uiHandActionType: 'nothing',
    uiHandActivatePreviousState: false,
    isNotBackCameraFlip: true,
};
const STORE_NAME = 'features/base/settings';
/**
 * Sets up the persistence of the feature {@code base/settings}.
 */
const filterSubtree = {};
// start with the default state
Object.keys(DEFAULT_STATE).forEach(key => {
    const key1 = key;
    // @ts-ignore
    filterSubtree[key1] = true;
});
// we want to filter these props, to not be stored as they represent
// what is currently opened/used as devices
// @ts-ignore
filterSubtree.audioOutputDeviceId = false;
filterSubtree.cameraDeviceId = false;
filterSubtree.micDeviceId = false;
// hansoo
// Register the `isRecording` state for persistence
// Hansoo's additions
filterSubtree.isRecording = false; // Do not persist recording state
filterSubtree.isAnnotationDrawErase = false; // Do not persist annotation toggle
filterSubtree.uiButtonPosition = { visible: false, x: 0, y: 0 }; // Reset position
filterSubtree.uiHandMenuPosition = { visible: false, x: 0, y: 0 }; // Reset position
filterSubtree.lastHoveredButtonIndex = 0; // Reset index
filterSubtree.uiMouseOrHand = true; // mouse is true, if false -> means using hand
filterSubtree.uiHandActionType = 'nothing'; // empty = no action, annotate, erase, clear, snapshot, record, stop record
filterSubtree.uiHandActivatePreviousState = false; // empty = no action, annotate, erase, clear, snapshot, record, stop record
filterSubtree.isNotBackCameraFlip = true; // empty = no action, annotate, erase, clear, snapshot, record, stop record
// filterSubtree.flipHandUI = false; // Reset index
PersistenceRegistry.register(STORE_NAME, filterSubtree, DEFAULT_STATE);
ReducerRegistry.register(STORE_NAME, (state = DEFAULT_STATE, action) => {
    // console.log('hansoo redux case,',  action.type);
    switch (action.type) {
        case APP_WILL_MOUNT:
            return _initSettings(state);
        case SETTINGS_UPDATED:
            return {
                ...state,
                ...action.settings
            };
        // // // Handle toggling video recording state
        case TRIGGER_ANNOTATION_CLEAR:
            const event_annotation_clear = new CustomEvent('AnnotationClearTrigger', {
            // detail: {
            //     state: state  // Pass the state in the 'detail'
            // }
            });
            window.dispatchEvent(event_annotation_clear); // Dispatch the event on the window
            return state;
        //
        // hansoo added start
        case TOGGLE_ANNOTATION_DRAW_ERASE:
            const drawEraseEvent = new CustomEvent('AnnotationDrawEraseToggle');
            window.dispatchEvent(drawEraseEvent); // Trigger annotation toggle event
            return {
                ...state,
                isAnnotationDrawErase: !state.isAnnotationDrawErase, // Toggle state
            };
        // Handle toggling video recording state
        case TRIGGER_IMAGE_SNAPSHOT:
            // console.log('TRIGGER_IMAGE_SNAPSHOT called');
            const snapshotEvent = new CustomEvent('imageSnapshotActivated', {
                detail: { message: 'Image Snapshot triggered!' },
            });
            window.dispatchEvent(snapshotEvent); // Trigger snapshot event
            return state;
        case TOGGLE_VIDEO_RECORDING:
            console.log('VideoRecord state--->', action.payload);
            // }
            return {
                ...state,
                isRecording: action.payload, //!state.isRecording, // Toggle recording state
            };
        case TOGGLE_MOUSE_HAND_ANNOTATION:
            // const event_annotation_mouse_hand_toggle = new CustomEvent('AnnotationMouseHandToggle', {
            //     detail: {
            //         state: !state.uiMouseOrHand  // Pass the state in the 'detail'
            //     }
            // });
            // console.log('reducer.ts event_annotation_mouse_hand_toggle');
            // window.dispatchEvent(event_annotation_mouse_hand_toggle); // Dispatch the event on the window
            // return {
            //     ...state,
            //     uiMouseOrHand: !state.uiMouseOrHand, // Toggle state
            // };
            return {
                ...state,
            };
        case TRIGGER_FLIP_WINDOW_UI:
            // const event_flip_window= new CustomEvent('isNotBackCameraFlip', {
            //     detail: {
            //         state: !state.isNotBackCameraFlip  // Pass the state in the 'detail'
            //     }
            // });
            // console.log('reducer.ts event_flip_window');
            // window.dispatchEvent(event_flip_window); // Dispatch the event on the window
            // return {
            //     ...state,
            //     isNotBackCameraFlip: !state.isNotBackCameraFlip, // Toggle flip
            // };
            return {
                ...state,
            };
        case LAST_HOVERED_BUTTON_INDEX:
            // console.log('hansoo reducer.ts UPDATE_UI_BUTTON_POSITION called->>', action.payload);
            return {
                ...state,
                lastHoveredButtonIndex: action.payload
            };
        case UPDATE_UI_BUTTON_POSITION:
            if (!action.payload || typeof action.payload.x !== 'number' || typeof action.payload.y !== 'number') {
                console.error('Invalid payload for UPDATE_UI_BUTTON_POSITION');
                return state;
            }
            // check if activating ui menu button with pinch, trigger on (true to false) from pinch && valid ui button number
            // start
            // console.log('x hansoo hand activating button number-', action.payload.visible , state.lastHoveredButtonIndex);
            const newState = {
                ...state,
                uiButtonPosition: action.payload,
            };
            if (action.payload.visible) {
                newState.uiHandActivatePreviousState = true;
            }
            else if (state.uiHandActivatePreviousState) {
                // console.log('hansoo hand activating button number-', state.lastHoveredButtonIndex);
                // find function to activate
                if (newState.lastHoveredButtonIndex === 1) {
                    const drawEraseEvent = new CustomEvent('AnnotationDrawEraseToggle');
                    window.dispatchEvent(drawEraseEvent); // Trigger annotation toggle event
                    newState.isAnnotationDrawErase = !newState.isAnnotationDrawErase;
                }
                if (newState.lastHoveredButtonIndex === 2) {
                    const event_annotation_clear = new CustomEvent('AnnotationClearTrigger', {
                    // detail: {
                    //     state: state  // Pass the state in the 'detail'
                    // }
                    });
                    window.dispatchEvent(event_annotation_clear); // Dispatch the event on the window
                }
                if (newState.lastHoveredButtonIndex === 3) {
                    const snapshotEvent = new CustomEvent('imageSnapshotActivated', {
                        detail: { message: 'Image Snapshot triggered!' },
                    });
                    window.dispatchEvent(snapshotEvent); // Trigger snapshot event
                    // return state;
                }
                if (newState.lastHoveredButtonIndex === 4) {
                    // isRecording
                    // if (window.videoRecordInstance) {
                    //     window.videoRecordInstance.toggleRecording();
                    // }
                    const event = new CustomEvent('videoRecordingToggled', {
                    // detail: leftHandData
                    });
                    window.dispatchEvent(event);
                    if (newState.isRecording) {
                        newState.isRecording = false;
                    }
                }
                if (newState.lastHoveredButtonIndex === 5) {
                    const event_flip_window = new CustomEvent('isNotBackCameraFlip', {
                        detail: {
                            isNotBackCameraFlipstate: !state.isNotBackCameraFlip // Pass the state in the 'detail'
                        }
                    });
                    // console.log('reducer.ts event_flip_window');
                    newState.isNotBackCameraFlip = !state.isNotBackCameraFlip;
                    window.dispatchEvent(event_flip_window); // Dispatch the event on the window
                }
                if (newState.lastHoveredButtonIndex === 6) {
                    const event_annotation_mouse_hand_toggle = new CustomEvent('AnnotationMouseHandToggle', {
                        detail: {
                            mousehandstate: !state.uiMouseOrHand // Pass the state in the 'detail'
                        }
                    });
                    newState.uiMouseOrHand = !state.uiMouseOrHand;
                    window.dispatchEvent(event_annotation_mouse_hand_toggle); // Dispatch the event on the window
                    // console.log('reducer.ts event_annotation_mouse_hand_toggle');
                }
                //////
                // Reset lastHoveredButtonIndex after processing
                newState.uiHandActivatePreviousState = false;
                newState.lastHoveredButtonIndex = 0;
            }
            return newState;
        // if(action.payload.activate)
        // {
        //     state.uiHandActivatePreviousState = true;
        // }
        // else
        // {
        //     if(state.uiHandActivatePreviousState)
        //     {
        //         state.uiHandActivatePreviousState = false;
        //         console.log('hansoo hand activating button number-', state.lastHoveredButtonIndex);
        //         //
        //         state.lastHoveredButtonIndex = 0;
        //     }
        // }
        // end
        //
        // return {
        //     ...state,
        //     uiButtonPosition: action.payload,
        // };
        case UPDATE_UI_HAND_MENU_POSITION:
            if (!action.payload || typeof action.payload.x !== 'number' || typeof action.payload.y !== 'number') {
                console.error('Invalid payload for UPDATE_UI_HAND_MENU_POSITION');
                return state;
            }
            return {
                ...state,
                lastHoveredButtonIndex: 0,
                uiHandMenuPosition: action.payload,
            };
        default: // Ensure unhandled actions return the current state
            return state;
    }
    // return state;
});
/**
 * Inits the settings object based on what information we have available.
 * Info taken into consideration:
 *   - Old Settings.js style data.
 *
 * @private
 * @param {ISettingsState} featureState - The current state of the feature.
 * @returns {Object}
 */
function _initSettings(featureState) {
    let settings = featureState;
    // Old Settings.js values
    // FIXME: jibri uses old settings.js local storage values to set its display
    // name and email. Provide another way for jibri to set these values, update
    // jibri, and remove the old settings.js values.
    const savedDisplayName = jitsiLocalStorage.getItem('displayname');
    const savedEmail = jitsiLocalStorage.getItem('email');
    // The helper _.escape will convert null to an empty strings. The empty
    // string will be saved in settings. On app re-load, because an empty string
    // is a defined value, it will override any value found in local storage.
    // The workaround is sidestepping _.escape when the value is not set in
    // local storage.
    const displayName = savedDisplayName === null ? undefined : escape(savedDisplayName);
    const email = savedEmail === null ? undefined : escape(savedEmail);
    settings = assignIfDefined({
        displayName,
        email
    }, settings);
    // return settings;
    // hansoo added
    return settings || DEFAULT_STATE;
}
// hansoo added start
// export { ISettingsState, DEFAULT_STATE };
// hansoo added end
