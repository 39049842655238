import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { isMobileBrowser } from '../../../base/environment/utils';
import { getLocalParticipant, isLocalParticipantModerator } from '../../../base/participants/functions';
import { isReactionsButtonEnabled, shouldDisplayReactionsButtons } from '../../../reactions/functions.web';
import { isTranscribing } from '../../../transcribing/functions';
import { setHangupMenuVisible, setOverflowMenuVisible, setToolbarHovered } from '../../actions.web';
import { getJwtDisabledButtons, getVisibleButtons } from '../../functions.web';
import { useKeyboardShortcuts, useToolboxButtons } from '../../hooks.web';
import Separator from './Separator';
// hansoo start
// import { toggleVideoRecording } from  '../../actions.ts';
import { triggerImageSnapshot, toggleAnnotationDrawErase, triggerAnnotationClear, updatelastHoveredButtonIndex, toggleMouseHandAnnotation, triggerFlipWindowUI } from '../../../base/settings/actions';
const useStyles = makeStyles()(() => {
    return {
        contextMenu: {
            position: 'relative',
            right: 'auto',
            margin: 0,
            marginBottom: '8px',
            maxHeight: 'calc(100dvh - 100px)',
            minWidth: '240px'
        },
        hangupMenu: {
            position: 'relative',
            right: 'auto',
            display: 'flex',
            flexDirection: 'column',
            //             flexDirection: 'vertical',
            rowGap: '8px',
            margin: 0,
            padding: '16px',
            marginBottom: '4px'
        }
    };
});
/**
 * A component that renders the main toolbar.
 *
 * @param {IProps} props - The props of the component.
 * @returns {ReactElement}
 */
export default function Toolbox({ toolbarButtons }) {
    const { classes, cx } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _toolboxRef = useRef(null);
    const conference = useSelector((state) => state['features/base/conference'].conference);
    const isNarrowLayout = useSelector((state) => state['features/base/responsive-ui'].isNarrowLayout);
    const clientWidth = useSelector((state) => state['features/base/responsive-ui'].clientWidth);
    const isModerator = useSelector(isLocalParticipantModerator);
    const customToolbarButtons = useSelector((state) => state['features/base/config'].customToolbarButtons);
    const iAmRecorder = useSelector((state) => state['features/base/config'].iAmRecorder);
    const iAmSipGateway = useSelector((state) => state['features/base/config'].iAmSipGateway);
    const overflowDrawer = useSelector((state) => state['features/toolbox'].overflowDrawer);
    const shiftUp = useSelector((state) => state['features/toolbox'].shiftUp);
    const overflowMenuVisible = useSelector((state) => state['features/toolbox'].overflowMenuVisible);
    const hangupMenuVisible = useSelector((state) => state['features/toolbox'].hangupMenuVisible);
    const buttonsWithNotifyClick = useSelector((state) => state['features/toolbox'].buttonsWithNotifyClick);
    const reduxToolbarButtons = useSelector((state) => state['features/toolbox'].toolbarButtons);
    const toolbarButtonsToUse = toolbarButtons || reduxToolbarButtons;
    const chatOpen = useSelector((state) => state['features/chat'].isOpen);
    const isDialogVisible = useSelector((state) => Boolean(state['features/base/dialog'].component));
    const jwt = useSelector((state) => state['features/base/jwt'].jwt);
    const localParticipant = useSelector(getLocalParticipant);
    const transcribing = useSelector(isTranscribing);
    // Do not convert to selector, it returns new array and will cause re-rendering of toolbox on every action.
    const jwtDisabledButtons = getJwtDisabledButtons(transcribing, isModerator, jwt, localParticipant?.features);
    const reactionsButtonEnabled = useSelector(isReactionsButtonEnabled);
    const _shouldDisplayReactionsButtons = useSelector(shouldDisplayReactionsButtons);
    // hansoo
    const toolbarVisible = true; //useSelector(isToolboxVisible);
    const mainToolbarButtonsThresholds = useSelector((state) => state['features/toolbox'].mainToolbarButtonsThresholds);
    const allButtons = useToolboxButtons(customToolbarButtons);
    /*
        const isRecording = useSelector(state => state['features/base/settings'].isRecording);
        const isAnnotationDrawErase = useSelector(state => state['features/base/settings'].isAnnotationDrawErase);
        const uiButtonPosition = useSelector(state => state['features/base/settings'].uiButtonPosition);
        const lastHoveredButtonIndex = useSelector(state => state['features/base/settings'].lastHoveredButtonIndex);
        const uiHandMenuPosition = useSelector(state => state['features/base/settings'].uiHandMenuPosition);
        */
    const isRecording = useSelector((state) => state['features/base/settings'].isRecording);
    const isAnnotationDrawErase = useSelector((state) => state['features/base/settings'].isAnnotationDrawErase);
    const uiButtonPosition = useSelector((state) => state['features/base/settings'].uiButtonPosition);
    const lastHoveredButtonIndex = useSelector((state) => state['features/base/settings'].lastHoveredButtonIndex || 0);
    const uiHandMenuPosition = useSelector((state) => state['features/base/settings'].uiHandMenuPosition);
    const uiMouseOrHand = useSelector((state) => state['features/base/settings'].uiMouseOrHand);
    const isNotBackCameraFlip = useSelector((state) => state['features/base/settings'].isNotBackCameraFlip);
    //     const uiHandActionType = useSelector((state: IReduxState) => state['features/base/settings'].uiHandActionType);
    //     const isflipHandUI = useSelector((state: IReduxState) => state['features/base/settings'].isflipHandUI);
    //     const localFlipX = useSelector((state: IReduxState) => state['features/base/settings'].localFlipX);
    //     const lastHoveredButtonIndex = useSelector(state => state['features/base/settings'].lastHoveredButtonIndex);
    useKeyboardShortcuts(toolbarButtonsToUse);
    useEffect(() => {
        //hansoo
        //         if (!toolbarVisible) {
        //             if (document.activeElement instanceof HTMLElement
        //                 && _toolboxRef.current?.contains(document.activeElement)) {
        //                 document.activeElement.blur();
        //             }
        //         }
    }, [toolbarVisible]);
    /**
     * Sets the visibility of the hangup menu.
     *
     * @param {boolean} visible - Whether or not the hangup menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    const onSetHangupVisible = useCallback((visible) => {
        dispatch(setHangupMenuVisible(visible));
        dispatch(setToolbarHovered(visible));
    }, [dispatch]);
    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    const onSetOverflowVisible = useCallback((visible) => {
        dispatch(setOverflowMenuVisible(visible));
        dispatch(setToolbarHovered(visible));
    }, [dispatch]);
    useEffect(() => {
        if (hangupMenuVisible && !toolbarVisible) {
            onSetHangupVisible(false);
            dispatch(setToolbarHovered(false));
        }
    }, [dispatch, hangupMenuVisible, toolbarVisible, onSetHangupVisible]);
    useEffect(() => {
        if (overflowMenuVisible && isDialogVisible) {
            onSetOverflowVisible(false);
            dispatch(setToolbarHovered(false));
        }
    }, [dispatch, overflowMenuVisible, isDialogVisible, onSetOverflowVisible]);
    /**
     * Key handler for overflow/hangup menus.
     *
     * @param {KeyboardEvent} e - Esc key click to close the popup.
     * @returns {void}
     */
    const onEscKey = useCallback((e) => {
        if (e?.key === 'Escape') {
            e?.stopPropagation();
            hangupMenuVisible && dispatch(setHangupMenuVisible(false));
            overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
        }
    }, [dispatch, hangupMenuVisible, overflowMenuVisible]);
    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    const onMouseOut = useCallback(() => {
        //         !overflowMenuVisible && dispatch(setToolbarHovered(false));
    }, [dispatch, overflowMenuVisible]);
    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    const onMouseOver = useCallback(() => {
        //         dispatch(setToolbarHovered(true));
    }, [dispatch]);
    /**
     * Handle focus on the toolbar.
     *
     * @returns {void}
     */
    const handleFocus = useCallback(() => {
        //         dispatch(setToolboxVisible(true));
    }, [dispatch]);
    /**
     * Handle blur the toolbar..
     *
     * @returns {void}
     */
    const handleBlur = useCallback(() => {
        //         dispatch(setToolboxVisible(false));
    }, [dispatch]);
    if (iAmRecorder || iAmSipGateway) {
        return null;
    }
    const endConferenceSupported = Boolean(conference?.isEndConferenceSupported() && isModerator);
    const isMobile = isMobileBrowser();
    const rootClassNames = `new-toolbox ${toolbarVisible ? 'visible' : ''} ${toolbarButtonsToUse.length ? '' : 'no-buttons'} ${chatOpen ? 'shift-right' : ''}`;
    const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
    const containerClassName = `toolbox-content${isMobile || isNarrowLayout ? ' toolbox-content-mobile' : ''}`;
    const { mainMenuButtons, overflowMenuButtons } = getVisibleButtons({
        allButtons,
        buttonsWithNotifyClick,
        toolbarButtons: toolbarButtonsToUse,
        clientWidth,
        jwtDisabledButtons,
        mainToolbarButtonsThresholds
    });
    //     const ButtonRenderer = ({ button }) =>
    const ButtonRenderer = ({ button }) => (React.createElement("button", { onClick: button.onClick, style: {
            padding: '0px',
            borderRadius: '5px',
            //                 backgroundColor: '#FFFFFF', // White background
            //                 backgroundColor: '#CCCCCC', // Light grey background
            backgroundColor: '#333',
            color: '#fff',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80px',
            height: '80px', // Adjust as needed
            //                 width: '50px', // Set a square size for consistency
            //                 height: '50px', // Adjust as needed
        } },
        React.createElement("span", { style: { fontSize: '30px', display: 'inline-flex' } }, button.icon),
        React.createElement("span", null, button.label)));
    // recording state start
    //     const [isRecording, setIsRecording] = useState(false);
    //
    //     // Step 2: Toggle recording status on button click
    //     const handleVideoButtonClick = () => {
    //         setIsRecording((prevState) => !prevState); // Toggle the recording state
    //         console.log(isRecording ? 'Video Record Off activated!' : 'Video Record On activated!');
    //     };
    // recording state end
    //         uiButtonPosition?: {
    //         visible: boolean; // Indicates if the button should be visible
    //         x: number; // X-coordinate of the button's position
    //         y: number; // Y-coordinate of the button's position
    // Handle button click
    //     const handleVideoButtonClick = () => {
    //         console.log('Video clicked!');
    //         dispatch(toggleVideoRecording()); // Dispatch the toggleVideoRecording action
    //     };
    // triggerImageSnapshot();
    const customButton = [
        //         {
        //             key: 'pinch-click',
        //             icon: '🤏', // Pinch gesture
        //             label: '',
        //             onClick: () => console.log('Pinch Click activated!'),
        //         },
        {
            key: 'nothing',
            icon: lastHoveredButtonIndex === 0 ? '🤔👈' : '🤔',
            //             label: 'nothing',
            label: '',
            onClick: () => {
                console.log('nothing top is clicked');
            },
            //             style: {
            // //                 fontSize: lastHoveredButtonIndex === 0 ? '200%' : '100%', // Makes icon 1.5x larger when recording
            // //                 fontSize: '200%' , // Makes icon 1.5x larger when recording
            //                 backgroundColor: 'grey',
            //             }
            //             onClick: () => console.log('Draw Erase activated!'),
        },
        {
            key: 'drawerase',
            //             icon: '✏️', // Pencil
            icon: lastHoveredButtonIndex === 1 ? isAnnotationDrawErase ? '🧽👈' : '✏️👈' : isAnnotationDrawErase ? '🧽' : '✏️',
            //             label: 'toggle draw or erase annotation',
            label: '',
            onClick: () => {
                //                 const buttonId = event.currentTarget.id;
                //                 if (buttonId) {
                //                     console.log(`Button ${buttonId} clicked`);
                //                 } else {
                //                     console.log('Button clicked, but no id found');
                //                 }
                //                 console.log(`Button drawerase clicked: ${event.target.id}`);  // Log the ID of the clicked button
                dispatch(toggleAnnotationDrawErase());
            },
            //             onClick: () => console.log('Draw Erase activated!'),
        },
        //         {
        //             key: 'erase',
        // //             icon: '🩹', // Bandage (for erasing or undoing)
        //             icon: '🧽', // Sponge
        //             label: '',
        //             onClick: () => console.log('Erase activated!'),
        //         },
        {
            key: 'clear',
            //             icon: '🗑️', // Trash can
            icon: lastHoveredButtonIndex === 2 ? '🧹👈' : '🧹',
            //             label: 'clear annotation',
            label: '',
            onClick: () => {
                //                 const buttonId = event.currentTarget.id;
                //                 if (buttonId) {
                //                     console.log(`Button ${buttonId} clicked`);
                //                 } else {
                //                     console.log('Button clicked, but no id found');
                //                 }
                //                 console.log(`Button clear clicked: ${event.target.id}`);  // Log the ID of the clicked button
                dispatch(triggerAnnotationClear());
            },
            //             onClick: () => console.log('Clear activated!'),
        },
        {
            key: 'image-snapshot',
            icon: lastHoveredButtonIndex === 3 ? '📸👈' : '📸',
            //             label: 'image snapshot',
            label: '',
            //             onClick: dispatch(triggerImageSnapshot()), // Toggle recording state on click
            onClick: () => {
                //                 console.log(`Button image-snapshot clicked: ${event.target.id}`);  // Log the ID of the clicked button
                dispatch(triggerImageSnapshot());
                //                 console.log('hansoo Image Snapshot activated!');
                //                 // Create and dispatch a CustomEvent on the window
                //                 const event = new CustomEvent('imageSnapshotActivated', {
                //                     detail: {
                //                         message: 'hansoo Image Snapshot has been triggered!'
                //                         // ,
                //                         // time: new Date().toISOString() // Optional: add more details if needed
                //                     }
                //                 });
                //                 window.dispatchEvent(event); // Dispatch the event on the window
            },
        },
        //         {
        //             key: 'mute-button',
        //             icon: '🎤',
        //             label: '',
        // //             label: 'Mute',
        //             onClick: () => console.log('Mute clicked!'),
        //         },
        {
            key: 'video-button',
            icon: lastHoveredButtonIndex === 4 ? isRecording ? '🔴👈' : '📹👈' : isRecording ? '🔴' : '📹',
            //             icon: '📹',
            //             label: 'video record',
            label: '',
            //             label: 'Video',
            //             onClick: handleVideoButtonClick, // Handle click to toggle the state
            //             onClick: () => console.log('Video clicked!'),
            //             onClick: dispatch(toggleVideoRecording()), // Toggle recording state on click
            //             onClick: handleVideoButtonClick, // Toggle recording state on click
            onClick: () => {
                //                 console.log(`Button video-button clicked: ${event.target.id}`);  // Log the ID of the clicked button
                //                 dispatch(toggleVideoRecording());
                //                 if (window.videoRecordInstance) {
                //                     window.videoRecordInstance.toggleRecording();
                //                 }
                //                 else
                //                 {
                //                     console.error('VideoRecord instance not found');
                //                 }
            },
            //         dispatch(toggleVideoRecording()); // Dispatch the toggleVideoRecording action
            //     };
        },
        //         {
        //             key: 'video-record-on',
        //             icon: '🔴', // Red circle for recording
        //             label: '',
        //             onClick: () => console.log('Video Record On activated!'),
        //         },
        //         {
        //             key: 'video-record-off',
        //             icon: '⏹️', // Stop icon
        //             label: '',
        //             onClick: () => console.log('Video Record Off activated!'),
        //         },
        {
            key: 'flip',
            icon: lastHoveredButtonIndex === 5 ? '↔️👈' : '↔️',
            label: '',
            //             label: 'flip screen',
            onClick: () => {
                console.log('han sooFlip window horizontally clicked');
                dispatch(toggleMouseHandAnnotation);
            },
        },
        {
            key: 'mouseHandControl',
            icon: lastHoveredButtonIndex === 6 ? uiMouseOrHand ? '🖱️👈' : '✋👈' : uiMouseOrHand ? '🖱️' : '✋',
            label: 'use mouse or keyboard',
            onClick: () => {
                console.log('Mouse control clicked');
                dispatch(triggerFlipWindowUI);
            },
        },
    ];
    //     const checkAndTriggerButtonClick = (x, y) => {
    //         // Get the toolbox container
    //         const toolbox = document.querySelector('.toolbox-content-items');
    //
    //         if (!toolbox) {
    //             console.warn('Toolbox not found');
    //             return;
    //         }
    //
    //         // Loop through all child buttons inside the toolbox
    //         const buttons = toolbox.querySelectorAll('button'); // Select all button elements inside the toolbox
    //
    //         buttons.forEach(button => {
    //             const rect = button.getBoundingClientRect(); // Get the bounding box of the button
    //
    //             // Log the rect and the coordinates for better debugging
    // //             console.warn('Checking button', x, y, '<>', rect);
    //
    //             // Check individual conditions for each side
    //             let clicked = true;
    //             console.log('Checking button', x , rect.left);
    //
    //             if (x > rect.left) {
    //                 console.log('x 1');
    //                 clicked = false;
    //             }
    // //             if (x < rect.right) {
    // //                 console.log('x 2');
    // // //                 console.log(`x is greater than rect.right: ${x} > ${rect.right}`);
    // //                 clicked = false;
    // //             }
    // //             if (y < rect.top) {
    // //                 console.log('y 1');
    // // //                 console.log(`y is less than rect.top: ${y} < ${rect.top}`);
    // //                 clicked = false;
    // //             }
    // //             if (y > rect.bottom) {
    // //                 console.log('y 2');
    // // //                 console.log(`y is greater than rect.bottom: ${y} > ${rect.bottom}`);
    // //                 clicked = false;
    // //             }
    //
    //             // If all checks pass, trigger the button's click event
    // //             if (clicked) {
    // //                 console.log(`Button ${button.id || 'unknown'} clicked`);
    // //                 button.click(); // Trigger the click event
    // //             }
    //         });
    //     };
    //     let lastHoveredButtonIndex = null; // To track the last button the pointer was over
    //         const customButtons = customButton; // Get your custom buttons array
    // //         console.log('0 finger button ', x, y);
    //         customButtons.forEach(button => {
    // //             console.log('Checking button:', button.key);
    //             const buttonElement = document.querySelector(`#${button.key}`); // Each button has a unique key
    // //             console.log('Found button element:', buttonElement);
    //             if (buttonElement) {
    //                     const rect = buttonElement.getBoundingClientRect();
    // //                     console.log('Button bounds:', rect);
    //                     // Check if the point (x, y) lies within the button's bounding box
    // //                     console.log('finger button ', x, y, button.key, rect.left, rect.right, rect.top, rect.bottom);
    //                     if (
    //                         x >= rect.left &&
    //                         x <= rect.right &&
    //                         y >= rect.top &&
    //                         y <= rect.bottom
    //                     ) {
    //                         // Trigger the button's onClick handler
    //                         button.onClick();
    // //                         console.log(`${button.key} clicked!`);
    //                     }
    //             }
    //         });
    // floating button start
    const FloatingButton = () => {
        if (!uiButtonPosition) {
            console.warn('uiButtonPosition is undefined.');
            return null; // Return early if uiButtonPosition is not defined
        }
        const calculateHandUiPosition = () => {
            const Video = document.getElementById('largeVideo');
            if (!Video) {
                console.warn('Video element not found.');
                return { top: 50, left: 50 }; // Default values
            }
            const rect = Video.getBoundingClientRect();
            const top = rect.top + (uiButtonPosition.y || 0) * rect.height;
            const left = rect.left + ((isNotBackCameraFlip ? uiButtonPosition.x : 1 - uiButtonPosition.x) || 0) * rect.width;
            return { top, left };
        };
        //
        const { top, left } = calculateHandUiPosition();
        //
        //     if (!uiButtonPosition.visible) {
        //         return null; // Do not render if the button is not visible
        //     }
        //
        if (uiHandMenuPosition?.visible) {
            const checkAndTriggerButtonClick = (y) => {
                // Get the toolbox container
                const toolbox = document.querySelector('.toolbox-content-items');
                if (!toolbox) {
                    console.warn('Toolbox not found');
                    return;
                }
                // Select all button elements inside the toolbox
                const buttons = toolbox.querySelectorAll('button');
                if (!buttons.length) {
                    console.warn('No buttons found in the toolbox');
                    return;
                }
                //             if(lastHoveredButtonIndex)
                //             {
                if (lastHoveredButtonIndex > 0) {
                    const rect = buttons[lastHoveredButtonIndex].getBoundingClientRect();
                    if (y >= rect.top && y <= rect.bottom) {
                        //                     button_found = true;
                    }
                    else {
                        dispatch(updatelastHoveredButtonIndex(0)); // Update the last hovered button index
                    }
                }
                else {
                    buttons.forEach((button, index) => {
                        const rect = button.getBoundingClientRect();
                        if (y >= rect.top && y <= rect.bottom && index !== lastHoveredButtonIndex) {
                            dispatch(updatelastHoveredButtonIndex(index)); // Update the last hovered button index
                        }
                    });
                }
                //             }
            };
            //             let button_found = false;
            //                     button_found = true;
            checkAndTriggerButtonClick(top); // Trigger the button click check if the hand menu is visible
        }
        // if (uiHandMenuPosition?.visible) {
        //     const checkAndTriggerButtonClick = (y: number) => {
        //         // Get the toolbox container
        //         const toolbox = document.querySelector('.toolbox-content-items');
        //
        //         if (!toolbox) {
        //             console.warn('Toolbox not found');
        //             return;
        //         }
        //
        //         // Select all button elements inside the toolbox
        //         const buttons = toolbox.querySelectorAll('button');
        //         if (!buttons.length) {
        //             console.warn('No buttons found in the toolbox');
        //             // No buttons are present, reset the hovered button index to 0
        //             dispatch(updatelastHoveredButtonIndex(0));
        //             return;
        //         }
        //
        //         // Iterate over all buttons to check if the pointer is inside one
        //         let buttonFound = false;
        //         buttons.forEach((button, index) => {
        //             const rect = button.getBoundingClientRect();
        //             if (y >= rect.top && y <= rect.bottom) {
        //                 if (index !== lastHoveredButtonIndex) {
        //                     // Update the last hovered button index if it has changed
        //                     dispatch(updatelastHoveredButtonIndex(index));
        //                 }
        //                 buttonFound = true;
        //             }
        //         });
        //
        //         // If no button is found, reset the hovered button index to 0
        //         if (!buttonFound) {
        //             console.log('Pointer is not hovering over any button');
        //             dispatch(updatelastHoveredButtonIndex(0));
        //         }
        //     };
        //
        //     // Trigger the button click check if the hand menu is visible
        //     checkAndTriggerButtonClick(top);
        // }
        return (React.createElement("button", { style: {
                position: 'absolute',
                top: `${top}px`,
                left: `${left}px`,
                //                 top: `0px`,
                //                 left: `0px`,
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                backgroundColor: 'transparent',
                color: '#000',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                zIndex: 1000, // Ensure the button is on top
            } },
            React.createElement("span", { style: {
                    fontSize: '50px', // Increase the font size for a bigger icon
                } }, uiButtonPosition.visible ? isAnnotationDrawErase ? '✏️' : '🧽' : '🤔')));
    };
    // floating button end
    // flip button start
    //     const FlipUIButton = () => {
    //         return (
    //             <button
    //                 style={{
    //                     position: 'absolute',
    //                     top: '10px', // Distance from the top of the screen
    //                     left: '10px', // Distance from the left of the screen
    //                     padding: '10px 20px', // Button padding
    //                     backgroundColor: isflipHandUI ? '#28a745' : '#007BFF', // Change color based on flip state
    //                     color: '#FFF', // Button text color
    //                     border: 'none', // No border
    //                     borderRadius: '5px', // Rounded corners
    //                     cursor: 'pointer', // Pointer cursor on hover
    //                     zIndex: 1000, // Ensure the button is on top
    //                     fontSize: '16px', // Text size
    //                     fontWeight: 'bold', // Text weight
    //                     transform: isflipHandUI ? 'rotateY(180deg)' : 'none', // Optional: Add a flipping effect
    //                     transition: 'transform 0.3s ease-in-out' // Smooth transition for the effect
    //                 }}
    //                 onClick={() => console.log('Flip button clicked!', isflipHandUI);
    //                     dispatch(triggerFlipHandUI());
    //                 } // Replace
    //             >
    //             </button>
    //         );
    //     }; //
    // flip button end
    const updatedMainMenuButtons = [
        //         ...customButton, // Your custom button
        ...customButton.map(button => ({
            key: button.key,
            Content: () => React.createElement(ButtonRenderer, { button: button })
        })),
        //         ...mainMenuButtons, // Existing buttons
    ];
    //     const updatedMainMenuButtons = [
    //         ...customButton.map(button => ({
    //             key: button.key,
    //             Content: () => (
    //                 <ButtonRenderer
    //                     button={button}
    //                     style={{
    //                         ...button.style,
    //                         backgroundColor: button.key === 'nothing' ? 'blue' : button.style.backgroundColor,
    //                     }}
    //                 />
    //             ),
    //         })),
    //         // Include other buttons if necessary...
    //         ];
    // hansoo add custom buttons start
    const raiseHandInOverflowMenu = overflowMenuButtons.some(({ key }) => key === 'raisehand');
    const showReactionsInOverflowMenu = _shouldDisplayReactionsButtons
        && ((!reactionsButtonEnabled && (raiseHandInOverflowMenu || isNarrowLayout || isMobile))
            || overflowMenuButtons.some(({ key }) => key === 'reactions'));
    const showRaiseHandInReactionsMenu = showReactionsInOverflowMenu && raiseHandInOverflowMenu;
    if (uiHandMenuPosition !== undefined) {
        // set hand left menu postion start
        const calculatePosition = () => {
            if (uiHandMenuPosition.visible) {
                const Video = document.getElementById('largeVideo');
                if (!Video) {
                    return { xtop: 0, xleft: 0 };
                }
                const rect = Video.getBoundingClientRect();
                const xtop = rect.top + uiHandMenuPosition.y * rect.height;
                //             const xleft = rect.left + (localFlipX ? 1 - uiHandMenuPosition.x :  uiHandMenuPosition.x) * rect.width;
                const xleft = rect.left + (isNotBackCameraFlip ? uiHandMenuPosition.x : 1 - uiHandMenuPosition.x) * rect.width;
                //                 if (uiButtonPosition.visible) {
                //                     // check hover and click button on y axis only start
                //                     checkAndTriggerButtonClick();
                //                     // check hover and click button on y axis only end
                //                 }
                return { xtop, xleft };
            }
            else {
                return { xtop: 0, xleft: 0 };
            }
        };
        const { xtop, xleft } = calculatePosition();
        //      console.log('hand ui menu-', uiHandMenuPosition  );
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cx(rootClassNames, shiftUp && 'shift-up'), id: 'new-toolbox', style: {
                    display: uiHandMenuPosition.visible ? 'flex' : 'none',
                    flexDirection: 'column',
                    position: 'fixed',
                    left: uiHandMenuPosition.visible ? `${xleft}px` : '10px',
                    top: uiHandMenuPosition.visible ? `${xtop}px` : '50%',
                    //                     left: '10px',
                    //                     top:  '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    padding: '20px',
                    width: '0px',
                    zIndex: 1000
                } },
                React.createElement("div", { className: containerClassName },
                    React.createElement("div", { className: 'toolbox--wrapper', style: { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }, onBlur: handleBlur, onFocus: handleFocus, ...(isMobile ? {} : {
                        //                     onMouseOut,
                        //                     onMouseOver
                        }) },
                        React.createElement("div", { className: 'toolbox-content-items', style: { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }, ref: _toolboxRef }, updatedMainMenuButtons.map(({ Content, key, ...rest }) => Content !== Separator && (React.createElement(Content, { ...rest, 
                            //                                     buttonKey = { key }
                            key: key }))))))),
            React.createElement(FloatingButton, null)));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(FloatingButton, null)));
        //         return null;
    }
}
//     return (
//         <div
//             id="new-toolbox"
//             style={{
//                 display: toolbarVisible ? 'flex' : 'none',
//                 flexDirection: 'column',
//                 position: 'fixed',
//                 left: 0,
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                 padding: '10px',
//                 borderRadius: '5px',
//             }}
//             onMouseOver={onMouseOver}
//             onMouseOut={onMouseOut}
//         >
//             {updatedMainMenuButtons.map((button) => (
//                 <button
//                     key={button.key}
//                     onClick={button.onClick}
//                     style={{
//                         padding: '10px',
//                         marginBottom: '8px',
//                         borderRadius: '5px',
//                         backgroundColor: '#333',
//                         color: '#fff',
//                         cursor: 'pointer',
//                         display: 'flex',
//                         alignItems: 'center',
//                         gap: '8px',
//                     }}
//                 >
//                     <span>{button.icon}</span>
//                     <span>{button.label}</span>
//                 </button>
//             ))}
//         </div>
//     );
