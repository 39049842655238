// import { TOGGLE_VIDEO_RECORDING} from '../base/settings/actions';
// import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoRecording } from '../base/settings/actions';
// if (!toggleVideoRecording) {
//     console.error('toggleVideoRecording is undefined.');
// } else {
//     this.store.dispatch({ type: toggleVideoRecording });
// }

class VideoRecord {
    constructor(store) {
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.isRecording = false;
        this.stream = null;
        this.store = store;
        // // Add event listener for 'r' key to toggle recording
        document.addEventListener('keydown', (event) => {
            if (event.key === 'r') {
                this.toggleRecording();
            }
        });
        // // listen to event jitsi-meet/react/features/base/settings/reducer.ts
        window.addEventListener('videoRecordingToggled', (event) => {
                this.toggleRecording();
        });

        // this.unsubscribe = this.store.subscribe(() => {
        //     const state = this.store.getState();
        //     this.isRecording = state['features/base/settings'].isRecording;
        // });

    }
    destroy() {
        // if (this.unsubscribe) {
        //     this.unsubscribe();
        // }
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    }
    // Method to toggle recording on and off
    async toggleRecording() {
        // if (!this.isRecording) {
        //     // Start recording
        //     await this.startRecording();
        // } else {
        //     // Stop recording
        //     this.stopRecording();
        // }
        // this.store.dispatch(toggleVideoRecording(this.isRecording));
        // try {
            if (!this.isRecording) {
                // Attempt to start recording
                const startedSuccessfully = await this.startRecording();
                if (startedSuccessfully) {
                    this.store.dispatch(toggleVideoRecording(startedSuccessfully));
                }
            } else {
                // Attempt to stop recording
                const stoppedSuccessfully = await this.stopRecording();
                if (stoppedSuccessfully) {
                    // this.store.dispatch(toggleVideoRecording(false));
                }
            }
        // }
        // catch (error) {
        //     console.error('Error toggling video recording:', error);
        // }
    }

    // Method to start recording
    async startRecording() {
        console.log('video record triggered', this.isRecording);
        try {
            // Request permission to capture the entire screen
            this.stream = await navigator.mediaDevices.getDisplayMedia({
                video: { mediaSource: 'screen' },
                audio: true // Set to true if you want to capture system or microphone audio
            });

            // Clear the buffer before starting a new recording
            this.recordedChunks = [];

            // Create a MediaRecorder instance to record the streams
            this.mediaRecorder = new MediaRecorder(this.stream);
            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                }
            };

            this.mediaRecorder.onstop = this.handleRecordingStop.bind(this);

            // Start recording
            this.mediaRecorder.start();
            this.isRecording = true;
            return true;
        } catch (error) {
            console.error('Error starting screen recording:', error);
            return false;
        }
    }

    // Method to stop recording
    stopRecording() {
        if (this.mediaRecorder && this.isRecording) {
            this.mediaRecorder.stop();
            this.isRecording = false;
            // this.dispatch(TOGGLE_VIDEO_RECORDING);
            // this.store.dispatch({ type: 'TOGGLE_VIDEO_RECORDING' });
            // this.store.dispatch({ type: TOGGLE_VIDEO_RECORDING });
            // Stop all tracks to release the stream
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
            }

            console.log('Screen recording stopped.');
            return true;
        }
        else
        {
            return false;
        }
    }

    // Method to handle when recording stops
    handleRecordingStop() {
        const recordedBlob = new Blob(this.recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(recordedBlob);

        // Print out the URL of the recorded video for preview or download
        console.log('Screen recording saved:', url);

        // Optionally, trigger download of the recorded video
        this.downloadRecording(url, 'screen_recording.webm');

        // Clear the buffer after saving the recording
        this.recordedChunks = [];
    }

    // Method to trigger a download of the recorded video
    downloadRecording(blobUrl, filename) {
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

// Example usage:
// const videoRecord = new VideoRecord(); // Create a VideoRecord instance
export { VideoRecord };
